@import "../../global.scss";

.contact{
    background-color: $black;
    display: flex;

        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        h2{
            font-size: 30px;
            color: $red;
        }

        form{
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            border-radius: 10px;

            input{
                width: 300px;
                height: 30px;
                font-size: 14px;
                border-radius: 10px;
                background-color: $gray;

                @include mobile{
                    width: 200px;
                    height: 20px;
                }
            }

            textarea{
                width: 300px;
                height: 400px;
                font-size: 14px;
                border-radius: 10px;
                background-color: $gray;
                
                @include mobile{
                    width: 200px;
                    height: 300px;
                }
            }

            button{
                width: 150px;
                height: 30px;
                color: white;
                background-color: $red;
                border:none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;

                &.focus{
                    outline: none;
                }
            }

            span{
                color: green;
            }
        }
}