@import "../../global.scss";

.intro{
    background-color: $black;
    display: flex;
    @include mobile{
            flex-direction: column;
            align-items: center;
        }


    .left{
        flex: 0.5;
        overflow: hidden;
        background-color:$black;

        .imgContainer{
            width: 100%;
            height: 100%;
            background-color: inherit;
            display: flex;
            align-items: flex-end;
            @include mobile{
                border-radius: 10%;
                margin-top: 5px;
                height: 90%;
            }
            
            img{
                height: 100%;
            }
        }
    }

    .right{
        flex: 0.5;
        position: relative;
        color: $white;

        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile{
                padding-left: 0;
                align-items: center;

            }

            h1{
                font-size: 60px;
                margin: 10px 0;
                color: $red;
            }
            h2{
                font-size: 35px;
            }
            h3{
                font-size: 30px;

                span{
                    font-size: inherit;
                    color: inherit;

                    @include mobile{
                        text-align: center;
                        text-overflow: string;
                    }
                }
            }
        }

       a{
           position: absolute;
           bottom: 10px;
           left: 0%;
           color: $white;

           @include mobile{
               bottom: 0px;
               align-items: center;
               left:40%;
           }
           icon{
               font-size: 24px;
           }
       }
    }
}