@import "../../global.scss";

.works{
    background-color:$black;
    display: flex;
    align-items: center;
    justify-content: center;

    h1{
        font-size: 50px;
        vertical-align: top;
        color:$white;
        height: 85vh;
    }

    .arrow{
        height:50px;
        position: absolute;
        cursor: pointer;
        color: $white;
        font-size: 100px;
        transition: all 1s ease;


        &.left{
            left: 100px;
            transform: scale(1.0) rotateY(180deg);
            @include mobile{
                left: 0px;
                margin: auto;
                width: 80%;
                margin-top: 80vh;
            }

            &:hover{
                transform: scale(1.1) rotateY(180deg);
            }
        }

        &.right{
            right: 100px;
            @include mobile{
                right: 0px;
                margin: auto;
                width: 80%;                
                margin-top: 80vh;
            }

            &:hover{
                transform: scale(1.1);
            }
        }
    }

    .slider{
        height: 500px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;
        color: $white;

        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
            scroll-snap-align: center;
        
            .item{
                width: 900px;
                height: 100%;
                background-color: $gray;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                
                @include mobile{
                    width: 90%;
                    height: 350px;
                    margin: 5px 0 ;
                }


                
                .left{
                    flex: 4;
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftContainer{
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .imgContainer{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color:$teal;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img{
                                width: 25px;

                            }
                        }

                        h2{
                            font-size: 20px;
                            color: inherit;

                            @include mobile{
                                font-size:15px;
                            }
                        }
                        p{
                            font-size: 13px;
                            color: inherit;
                        }
                        a{
                            .icon{
                                font-size: 50px;
                                cursor: pointer;
                                color: $white;

                                @include mobile{
                                    font-size: 25px;
                                }
                            }
                        }
                        
                    }
                }
                .right{
                    flex: 8;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    @include mobile{
                        flex: 0;
                    }

                    img{
                        width: 550px;
                        transform: rotate(3deg);
                    }
                }
            }
        }
    }
}