@import "../../global.scss";

.aboutme{
    background-color: $black;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1{
        font-size: 50px;
        color: $red;
        margin-top: 5vh;
        overflow: hidden;
    }

    .container{
        width: 90%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.5s;
        opacity: 1;

        .card{
            width: 80vw;
            height: 80%;
            border-radius: 10px;
            box-shadow: 0px 0px 15px -8px $red;
            justify-content: space-between;
            overflow: hidden;
            padding: 20px;
            background-color: $gray;
            transition: all 1s ease;

            &:hover{
                transform: scale(1.005);
            }

            .left{
                float: left;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;

                .leftContainer{

                    width: 100%;
                    h2{
                        font-size: 60px;

                        @include mobile{
                            font-size: 40px;
                        }

                        span{
                            color: $red;
                            font-weight: 700;
                        }
                    }
                    p{
                       padding: 10px; 
                       font-size: 20px;
                       font-weight: 400; 
                       line-height: 1.75;

                       @include mobile {
                           font-size: 12px;
                       }
                       span{
                           color: $white;
                           font-weight: 700;
                       }
                    }
                    .resume{
                        padding: 40px;
                        align-items: center;
                        justify-content: center;
                        a{
                            .resumeIcon{
                                font-size:40px;
                                color: $red;
                                cursor: pointer;
                            }
                        }
                       }
                    
                }
                
            }
            .right{
                float: right;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                height: 100%;
                @include mobile{
                    display: none;
                }
                .imgContainer{
                    justify-content: space-between;
                    img{
                        width: 425px;
                        border-radius: 10px;
                    }
                }
            }

        }
    }

    
}