// $black: #222831;
// $darkgray: #393E46;
// $teal: #00ADB5;
// $white: #EEEEEE;

$black: #171717;
$gray: #444444;

$teal: #08D9D6;
$red: #DA0037; 
$white: #EDEDED;

$width: 1219px;
$height: 583px;

@mixin mobile {
    @media (max-width: #{$width}){
        @content
    }
}